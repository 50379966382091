import React, { Component } from "react";
import {NavLink, HashRouter } from "react-router-dom";
import { useTranslation  } from "react-i18next";
import { Redirect } from "react-router";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from '../services/localStorageService';


function ServiceSubscription(){

  function loginEmail (){
    return LocalStorageService("get", "userEmail"); 
  }; 
  
  function getToken (){
    return LocalStorageService("get", "token"); 
  }; 

 const email = loginEmail();

 const token = getToken();

  const isLoggedin = token!=null;

  const { t } = useTranslation(); 
      return (       
        <div className="container">    
     
     <div className="akwaba-content-admin"> 
           <Row>
            <Col md={{ span: 2, offset: 4 }}>
              {" "}             
              <p></p>
            </Col>
          </Row>
          <Row>
            <HashRouter>
              <Col md={{ span: 4 , offset: 3}}>
                {" "}
               {isLoggedin && (<div>
                <span><b>Follow these steps to upload your property details</b></span> 
                <div className="admin-home">                                
                    <ol>
                    <li>Fill your personal info<br/></li>
                    <li>Fill the info about the property<br/></li>
                    <li>Fill info about the Place where the property is<br/></li>
                    <li>Upload mandatory picture and optional picture(maximum 3)<br/></li>
                    <li>Upload a 1mn  video of the property tour <br/> </li>
                    </ol>
                    </div>  
                    <div></div> 
                  <NavLink to="/uploadassets">
                    <div className="admin-link-to-post">
                    <b>Click here to register a property</b> <br/>                            
                    </div>
                  </NavLink> 
                              
                    
                </div>)}
                {!isLoggedin && (<div>
                  <span>Please Login. If not registered yet please register and login</span>
                  </div>)}
                
              </Col>
             
            </HashRouter>
          </Row> 

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
          <div></div> 
                  <NavLink to="/messageadmin">
                    <div className="admin-link-to-post">
                    <b>Voir mes messages</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row>

          <Row>
            <Col md={{ span: 2, offset: 4 }}>
          <div></div> 
                  <NavLink to="/bookingmessages">
                    <div className="admin-link-to-post">
                    <b>Voir mes demandes d'achats et reservations</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row>     


          <Row>
            <Col md={{ span: 2, offset: 4 }}>
          <div></div> 
                  <NavLink to="/interestexpressed">
                    <div className="admin-link-to-post">
                    <b>Voir mes expressions d'interets</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row> 

                   <Row>
            <Col md={{ span: 2, offset: 4 }}>
          <div></div> 
                  <NavLink to="/appointmentmessages">
                    <div className="admin-link-to-post">
                    <b>Voir mes demandes de rendez-vous</b> <br/>                            
                    </div>
                  </NavLink> 
                  </Col>
          </Row>         
          <Row>
            <Col md={{ span: 2, offset: 4 }}>
              {" "}             
              <p></p>
            </Col>
          </Row>         
        </div>
   
    </div>
    )
  }
 
export default (ServiceSubscription);