import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Home from "./clientapp/pages/Home";
import Services from "./clientapp/pages/Services";
import Contact from "./clientapp/pages/messages/SendContactMessage";
import Menue from "./clientapp/pages/Menue";
import References from "./clientapp/pages/References";
import Booking from "./clientapp/pages/Booking";
import Appointments from "./clientapp/pages/Appointments";
import Interets from "./clientapp/pages/Interets";
import Register from "./clientapp/registerlogin/Register";
import RegisterSimple from "./clientapp/registerlogin/RegisterSimple";
import Loginscreen from "./clientapp/registerlogin/LoginScreen";
import Login from "./clientapp/registerlogin/Login";
import MessageMyAdmin from "./clientapp/admin/MessageAdminPage";
import MyAdmin from "./clientapp/admin/MyAdminPage";
import ServiceSubscription from "./clientapp/admin/ServiceSubscription";
import Logout from "./clientapp/registerlogin/Logout";
import Authservice2 from "./clientapp/Authentication/AuthService";
import Partnerservice from "./clientapp/partners/Partnerservice";
import UsefullLinks from "./clientapp/admin/UsefullLinks";
import ThankYouUploaded from "./clientapp/uploadimages/ThankyouAfterUploaded";
import UploadAssets from "./clientapp/uploadimages/UploadTextInputs";
import AssetDetails from "./clientapp/uploadimages/AssetDetailPage";
import UploadVideo from "./clientapp/uploadimages/UploadVideo";
import UploadPictures from "./clientapp/uploadimages/UploadPictures";
import ThankyouAfterRegistration from "./clientapp/registerlogin/ThankyouAfterRegistration";
import BuyerService from "./clientapp/buyers/BuyerServices";
import BuyerMessages from "./clientapp/buyers/MyMessagePage";
import BuyerAppointments from "./clientapp/buyers/MyAppointments";
import BuyerProperties from "./clientapp/buyers/MyProperty";
import AppointmentMessages from "./clientapp/admin/appointments/AppointmentMessagePage";
import BookingMessages from "./clientapp/admin/bookings/BookingMessagesPage";
import ExpressedInterestMessages from "./clientapp/admin/interestexpressed/ExpressedInterestMessagesPage";

class Main extends Component {
  render() {
    return (
      <div>
        <HashRouter>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} /> 
          <Route path="/appointmentmessages" component={AppointmentMessages} /> 
          <Route path="/bookingmessages" component={BookingMessages} /> 
          <Route path="/interestexpressed" component={ExpressedInterestMessages} />
          <Route path="/buyerservices" component={BuyerService} /> 
          <Route path="/buyerappointments" component={BuyerAppointments} /> 
          <Route path="/   " component={BuyerProperties} /> 
          <Route path="/buyermessages" component={BuyerMessages} /> 
          <Route path="/uploadedthanks" component={ThankYouUploaded} />
          <Route path="/assetdetails" component={AssetDetails} />          
          <Route path="/services" component={Services} />
          <Route path="/menue" component={Menue} />
          <Route path="/reference" component={References} />
          <Route path="/booking" component={Booking} />
          <Route path="/appointments" component={Appointments} />
          <Route path="/interets" component={Interets} />          
          <Route path="/register" component={Register} />
          <Route path="/registersimple" component={RegisterSimple} />
          <Route path="/thankyouregistered" component={ThankyouAfterRegistration} /> 
          <Route path="/adminpage" component={MyAdmin} />
          <Route path="/servicesubscription" component={ServiceSubscription} />
          <Route path="/loginscreen" component={Loginscreen} />
          <Route path="/login" component={Login} />
          <Route path="/contact" component={Contact} />
          <Route path="/messageadmin" component={MessageMyAdmin} />
          <Route path="/usefulllinks" component={UsefullLinks} />
          <Route path="/partnerservice" component={Partnerservice} />
          <Route path="/logout" component={Logout} />         
          <Route path="/authservice2" component={Authservice2} />         
          <Route path="/uploadassets" component={UploadAssets} />
          <Route path="/uploadvideos" component={UploadVideo} />
          <Route path="/uploadpictures" component={UploadPictures} />          
          <Route />
        </HashRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
