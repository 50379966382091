import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useTranslation  } from "react-i18next";
import { createBrowserHistory } from 'history';
import AssetOptionalVideos from './AssetOptionalVideos' ;
import CommentOnProperty from '../pages/comments/CommentOnProperty';
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import GetUrl from "../services/urlService";
import LocalStorageService from '../services/localStorageService';
//https://codesandbox.io/s/c53q2?file=/src/App.js:58-172


  const AssetDetailPage = (props) => { 
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [imageextension, setImageextension] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [firstOpt, setFirstOpt] = useState('');
  const [secondOpt, setSecondOpt] = useState('');
  const [thirdOpt, setThirdOpt] = useState('');
  const [assetNote, setAssetNote] = useState('');
  const [assetName, setAssetName] = useState('');
  const [assetPrice, setAssetPrice] = useState('');
  const [assetDescription, setAssetDescription] = useState('');
  const [replyerName, setReplyerName] = useState('');
  const [reply, setReply] = useState('');
  const[propertyId, setpropertyId]= useState('');
  const[propertyName, setpropertyName]= useState('');
  const [mainImagesrc, setMainImagesrc] = useState();
  const [firstOptsrc, setFirstOptsrc] = useState();
  const [secondOptsrc, setSecondOptsrc] = useState();
  const [thirdOptsrc, setThirdOptsrc] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [videoPath, setVideoPath] = useState();
  const [videoPathIsNullOrEmpty, setVideoPathStatus] = useState(false);

  const mainimagepath = props.location.state;

  const { t } = useTranslation();

  useEffect(() => {
    setImageSource();
  }, [])

  const setImageSource = () =>  {   
  const imagepaths = props.location.state; 
  localStorage.setItem('mainimage', imagepaths?.mainimage);
  localStorage.setItem('opt1image', imagepaths?.opt1image);
  localStorage.setItem('opt2image', imagepaths?.opt2image);
  localStorage.setItem('opt3image', imagepaths?.opt3image);
  localStorage.setItem('video', imagepaths?.video);
  localStorage.setItem('assetNote', imagepaths?.assetNote);
  localStorage.setItem('assetDescription', imagepaths?.assetDescription);
  localStorage.setItem('assetName', imagepaths?.assetName);
  localStorage.setItem('assetPrice', imagepaths?.assetPrice);
  localStorage.setItem('reply', imagepaths?.reply);
  localStorage.setItem('replyerName', imagepaths?.replyerName); 
  localStorage.setItem('Id', imagepaths?.productId);
  localStorage.setItem('prodName', imagepaths?.productName);   

  ////set the picture paths
    const mainimage = imagepaths.mainimage??localStorage.getItem('mainimage');//this.props.prop_mainimage;//  
    const opt1image = imagepaths.opt1image??localStorage.getItem('opt1image');//this.props.prop_opt1image;//
    const opt2image = imagepaths.opt2image??localStorage.getItem('opt2image'); //this.props.prop_opt2image;//
    const opt3image = imagepaths.opt3image??localStorage.getItem('opt3image');//this.props.prop_opt2image;//
    const assetName = imagepaths.assetName??localStorage.getItem('assetName'); //this.props.prop_assetName;//
    const assetPrice = imagepaths.assetPrice??localStorage.getItem('assetPrice');//this.props.prop_assetPrice;//
    const assetNote = imagepaths.assetNote??localStorage.getItem('assetNote');//this.props.prop_assetNote;//
    const assetDescription = imagepaths.assetDescription??localStorage.getItem('assetDescription');//this.props.prop_assetDescription;//
    const replyerName = imagepaths.replyerName??localStorage.getItem('replyerName');//this.props.prop_replyerName;//
    const reply = imagepaths.reply??localStorage.getItem('reply'); //this.props.prop_reply;//
    const videoPath = imagepaths.video??localStorage.getItem('video');
    const propId = imagepaths.productId??localStorage.getItem('Id');
    const propName = imagepaths.productName??localStorage.getItem('prodName');

   setpropertyId(propId);

   setpropertyName(propName);

   setMainImagesrc(mainimage);
   
   setFirstOptsrc(opt1image); 

   setSecondOptsrc(opt2image);
  
   setThirdOptsrc(opt3image); 
   setAssetNote(assetNote);
   setAssetName(assetName);
   setAssetPrice(assetPrice);
   setAssetDescription(assetDescription); 
   setReplyerName(replyerName);
   setReply(reply);
   setVideoPath(videoPath);

   let isNullOrEmptyvideoPath = videoPath===''||videoPath===null;
   setVideoPathStatus(isNullOrEmptyvideoPath);
   
   }
  
  const dimensions = {
    width:"100%",
    height:"100%",
  }
  const items = [      
    {   
      src: mainImagesrc,    
      altText: "Slide 1",
      caption: "",   
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: firstOptsrc,
      altText: "Slide 2",
      caption: "",    
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: secondOptsrc,
      altText: "Slide 3",
      caption: "",    
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: thirdOptsrc,
      altText: "Slide 4",
      caption: "",     
      width:dimensions.width,
      height:dimensions.height,    
    }
  ];
  
  
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {     
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >        
     <CarouselCaption
          captionText={item.caption}         
        />
          <img src={item.src} alt={item.altText} class="detail-page-carousel-image" width={item.width} height={item.height}/>       
      </CarouselItem>
    );
  });
 


  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [reason, setReason] = useState('Je suis interesse par votre logement -ci , je vous contacte pour en discuter.');
  const [messageSent, setMessageSent] = useState('');
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [fnameerrormsg, setFirstNameerrormsg] = useState('');
  const [lnameerrormsg, setLastNameerrormsg] = useState('');
  const [titleerrormsg, setTitleerrormsg] = useState('');
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [phonenumbererrormsg, setPhoneNumbererrormsg] = useState('');
  const [reasonerrormsg, setReasonerrormsg] = useState('');
  const [propertyid, setPropertyid] = useState('Logement Numero '+  localStorage.getItem('Id'));
  const [propertyidforbookinginterest, setPropertyidforbookinginterest] = useState('');
  const [type, setType] = useState('Interet');
  

  const getApiPath = () => { 
    return GetUrl("bookings")
  };

  const handleSubmit = event => {
    console.log('handleSubmit ran');
    event.preventDefault(); // 👈️ prevent page refresh
   const body ={fname, lname, title,email,phonenumber,reason, propertyid, type }; 
    
   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
     if (validate()) {            
      const API_PATH = getApiPath();
      axios({
        method: "post",
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) {         
            setFirstName('');
            setLastName('');
            setTitle('');
            setEmail('');
            setPhoneNumber('');
            setReason('');
            setEmailerrormsg('');
            setFirstNameerrormsg('');
            setLastNameerrormsg('');
            setTitleerrormsg('');
            setEmailerrormsg('');
            setPhoneNumbererrormsg('');
            setReasonerrormsg('');
            setMessageSent(true);
            setClickedButtonButNotPosted(false);
            setClickedNotPostedMessage('');            
          }
        })
        .catch(function (error) {    
          console.log(error);
        });     
    } else { 
      setClickedButtonButNotPosted(true);
      setClickedNotPostedMessage(<p>{t("pages.contact.text.msginvalidform")} </p>);  
      console.log("Data was not sent");
      return;
    }
  }

 const  validate = ()=> {
    let isValid = true; 
    if (!title) {
      isValid = false;
      setTitleerrormsg(<p>{t("pages.contact.text.titleinvalidmsg")}</p>);
    }
    
    if (!fname) {
      isValid = false;
      setFirstNameerrormsg(<p>{t("pages.contact.text.firstnameinvalidmsg")}</p>);
    }

    if (!lname) {
      isValid = false;
      setLastNameerrormsg(<p>{t("pages.contact.text.lastnameinvalidmsg")}</p>);
    }

    if (!phonenumber) {
      isValid = false;
      setPhoneNumbererrormsg(<p>{t("pages.contact.text.phoneinvalidmsg")}</p>);
     }
    if (!email) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        setEmailerrormsg(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }
    
    // if (!reason) {
    //   isValid = false;
    //   setReasonerrormsg(<p>{t("pages.contact.text.subjectinvalidmsg")}</p>);
    // }    
    return isValid;
  };
  

  return ( 
    <div className="Container-detail-page">
      <p></p>
    <Row>    
    <Col md={{ span: 4, offset: 4 }}>
     <p>Numero d'identification du logement: {propertyId}</p> 
    </Col>
    </Row>
    <Row>    
    <Col md={{ span: 3, offset: 4 }}>
    <Carousel  className="carousel-panel" activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
    </Col>    
    </Row> 
    <p></p>
    <Row> 
    <Col md={{ span: 6, offset: 3 }}>
    <div className="hitback-description">   
        <p>{reply}</p>
        <div>{propertyName}</div>
     </div>     
    </Col>
    </Row> 
    <p></p> 
    <Row>  
    <Col  md={{ span: 6, offset: 3 }}>
         
    {!videoPathIsNullOrEmpty &&(

  <div className="body-pannel">   
       <video width="300px" height="400px" controls>
           <source src={videoPath} type="video/mp4" />
      </video>
  </div>
  )}
  </Col>   
  </Row>
    <p></p> 
    <Row> 
  <div  className="content-akwaba">
  
   <Accordion defaultActiveKey="0"> 
   <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
   
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body className="content-accordion" >
      <p><h4>👇</h4></p>  
      </Card.Body>
      </Accordion.Collapse>
    </Card>

    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
   Exprimer votre interet par rapport a ce logement en clickant ici pour remplir le formulaire.
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body className="content-accordion" >
      <form action="#">
      
        <div className="form-group">
        <label>Type de requete(ignorez ce champ) </label>
        <input
         readonly 
          id="type"
          name="type"
          type="text"
          value={type}
          onChange={event => setType(event.target.value)}          
          placeholder="Interet"        
        />          
        </div>
        <div className="form-group">
        <label>{t("pages.contact.text.title")} </label>
             <select name= "title" id="title" value={title}  onChange={event => setTitle(event.target.value)} >
                <option value="">{t("pages.contact.default")}</option>
                <option value={t("pages.contact.sir")}>{t("pages.contact.sir")}</option>
                <option value={t("pages.contact.madam")}>{t("pages.contact.madam")}</option>
                <option value={t("pages.contact.unspecified")}>{t("pages.contact.unspecified")}</option>  
                <option value={t("pages.contact.other")}>{t("pages.contact.other")}</option>                
              </select>
          <div className="text-danger">{titleerrormsg}</div>
        </div>

        <div className="form-group">
        <label>{t("pages.contact.text.firstname")} </label>
        <input
          id="first_name"
          name="first_name"
          type="text"
          value={fname}
          onChange={event => setFirstName(event.target.value)}          
          placeholder={t("pages.contact.text.firstnameph")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
         <label>{t("pages.contact.text.lastname")}</label>
        <input
          id="last_name"
          name="last_name"
          type="text"
          value={lname}
          onChange={event => setLastName(event.target.value)}
          placeholder={t("pages.contact.text.lastnameph")}
        />
          <div className="text-danger">{lnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>{t("pages.contact.text.email")}</label>
        <input
          id="email"
          name="email"
          type="text"
          value={email}
          onChange={event => setEmail(event.target.value)}
          placeholder={t("pages.contact.text.emailph")}
        />
          <div className="text-danger">{emailerrormsg}</div>
      </div>

      <div className="form-group">
              <label>{t("pages.contact.text.phone")}</label>
         <input
          id="phonenumber"
          name="phonenumber"
          type="text"
          onChange={event => setPhoneNumber(event.target.value)}
          placeholder={t("pages.contact.text.phoneph")}
          value={phonenumber}
        />
          <div className="text-danger">{phonenumbererrormsg}</div>
        </div>

        <div className="form-group">
        <label>Numero de logement</label>
        <input
          readonly 
          id="_id"
          name="propertyid"
          type="text"         
          value={propertyId}          
          onChange={event => setPropertyid(event.target.value)}
          placeholder={propertyId}        
        />            
       </div>


        <div className="form-group">
        <label>Expression d'interet</label>
        <textarea
          id="reason"
          name="reason"
          type="text"
          rows={4}
          value={reason}          
          onChange={event => setReason(event.target.value)}
          placeholder="Je suis interesse par votre logement -ci , je vous contacte pour en discuter. "
        />
          <div className="text-danger">{reasonerrormsg}</div>      
       </div>
     
       <input
              className="btn btn-primary"
              type="submit"
              onClick={(e) =>  {handleSubmit(e)}}
              defaultValue={t("pages.contact.text.submit")}
            />
       
        <div>
               {messageSent && (
                <div>
                  {t("pages.contact.text.thankyou1")} <br />
                  {t("pages.contact.text.thankyou2")} <br />                 
                  {t("pages.contact.text.thankyou3")}{" "}
                </div>
              )}
              
              {clickedButtonButNotPosted && (
                <div>
                  {clickedNotPostedMessage} <br />                  
                </div>
                 )}
         </div>
         </form>
        </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
 
  </div>
    </Row>


    <p></p>     
    </div>    
  );
};

export default (AssetDetailPage);
